<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    img?: string
    alt?: string
  }>(),
  {
    alt: '',
  },
)
</script>

<template>
  <img v-if="props.img" :src="props.img" :alt="props.alt" class="post-img" />
</template>

<style scoped>
.post-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 0.3em;
  margin-bottom: 3em;
}
</style>
